import React from 'react';
import Layout from '../components/layout';
import { Link, graphql, useStaticQuery } from 'gatsby';

import blogStyles from './blog.module.scss'

const Blog = () => {

    const data = useStaticQuery(graphql`
        query {
            allMarkdownRemark(sort: {fields: frontmatter___date, order: DESC}) {
                group(field: frontmatter___tags) {
                    edges {
                      node {
                        fields {
                          slug
                        }
                        frontmatter {
                          date
                          title
                        }
                      }
                    }
                    field
                    fieldValue
                  }
                distinct(field: frontmatter___tags)
                edges {
                    node {
                        frontmatter {
                            title
                            date
                            tags
                        }
                        html
                        excerpt
                        fields {
                            slug
                        }
                    }
                }
            }
            }
    `)



    return (
        <Layout>
            <h1>Blog</h1>
            <p>This blog is meant to store random analysis that I am doing on a semi-regular basis.  
                By clicking on the tags below you can filter the posts to sort out what is relevant to your interests.
            </p>
            {data.allMarkdownRemark.group.map((gr) => {
                return(
                    <div>
                        <h4 id={`${gr.fieldValue}`}>
                            {gr.fieldValue}
                        </h4>
                        <ol className={blogStyles.posts}>
                            {gr.edges.map((edge) => {
                                return (
                                <li className={blogStyles.post}>
                                    <Link to ={`/blog/${edge.node.fields.slug}`}>
                                        <h5>{edge.node.frontmatter.title}</h5>
                                        <p>{edge.node.frontmatter.date}</p>
                                    </Link>
                                </li>
                                )
                            })}
                        </ol>
                    </div>

                    )
            })}
        </Layout>
    )
}

export default Blog